




























import { Component, Vue } from "vue-property-decorator";
import Files from "@/components/InvestorRelations/Files.vue";
import MarkdownItVueLight from "markdown-it-vue/dist/markdown-it-vue-light.umd.min.js";
import "markdown-it-vue/dist/markdown-it-vue-light.css";

@Component({
  name: "TextComponent",
  props: {
    content: {}
  },
  components: { Files, MarkdownItVueLight }
})
export default class TextComponent extends Vue {
  apiRelativePath(text: string) {
    return (
      (text &&
        text.replaceAll(
          "/uploads/",
          `${this.$store.state.apiUrl}/uploads/`
        )) ||
      null
    );
  }
}
