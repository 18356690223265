<template>
  <div class="files" v-if="files.length > 0">
    <a
      v-for="(link, index) in files"
      :key="index"
      :href="$store.state.apiUrl + link.url"
      target="_blank"
    >
      {{ link.caption || link.name }}
    </a>
  </div>
</template>

<script>
  export default {
    name: "Files",
    props: {
      files: {}
    }
  };
</script>

<style scoped lang="scss">
  .files {
    text-align: left;
    padding-bottom: 2rem;
    a {
      display: block;
      color: black;
      line-height: 2;
    }
  }
</style>
